<template>
  <div class="hinst">
    <div class="mtit1">
      <h2 class="cn">入驻企业</h2>
      <div class="en">Settled enterprise</div>
      <div class="line"></div>
    </div>
    <div class="hinst-hd">
      <ul class="ulFlex">
        <li :class="item.itemValue == currId ? 'active' : ''"
            v-for="item in list"
            :key="item.id"
            @click="toTaglet(index, item.itemValue)">
          {{ item.itemText }}
        </li>
      </ul>
    </div>
    <div class="hinst-bd">
      <ul>
        <li v-for="item in companyList"
            :key="item.id"
            class="hinst-bd-child">
          <div class="img"><img :src="item.companyLogo" /></div>
          <div class="txt">
            <p>
              {{ item.companyIntroduce }}
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="hinst-ft">
      <a @click="toRouter('apply',{cn:'发布需求',en:'Release requirements',type:6})"
         class="mfullbtn"><img src="../../../assets/images/icon32.png" />发布需求</a>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      list: [],
      companyList: [],
      currId: 1,
    };
  },
  async mounted () {
    //供需对接-入驻企业类别
    await this.getCompanyTypeList();
    //供需对接-入驻企业
    await this.getCompanyList(1);
  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };

      this.newRouter('/' + routerName, query)
    },
    toTaglet (idex, itemValue) {
      this.currId = itemValue;
      this.getCompanyList(itemValue);
    },
    //供需对接-入驻企业
    getCompanyList (index) {
      this.api
        .getCompanyList({ pageNo: 1, pageSize: 50, mainBusiness: index })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.companyList = records;
          console.log(records, 11);
        });
    },
    //供需对接-入驻企业类别
    getCompanyTypeList () {
      this.api.getCompanyTypeList().then((res) => {
        const {
          data: { result },
        } = res;
        this.list = result || [];
      });
    },
  },
};
</script>
<style scoped lang="scss">
.hinst-bd .img img {
  // width: 100%;
  // height: 100%;
}
@media screen and (max-width: 1201px) {
 .hinst-bd-child.hinst-bd-child{
      width: 100%;
  }
}
</style>
