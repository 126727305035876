<template>
  <div class="mrow mcase">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">合作案例</h2>
        <div class="en">Cooperation Case</div>
        <div class="line"></div>
      </div>
      <div class="mcase-list">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li v-for="item in list"
                :key="item.id"
                class="swiper-slide">
              <a>
                <div class="img">
                  <img :src="item.cover" />
                </div>
                <div class="txt">
                  {{ item.articleAbstract }}
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="swiper-button-prev mcase-prev"></div>
        <div class="swiper-button-next mcase-next"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    //供需对接-合作案列
    this.getArticleList();
  },
  methods: {
    toRouter () {
      newRouter("apply");
    },
    swiperInit () {
      new Swiper(".mcase-list .swiper-container", {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        nextButton: ".mcase-next",
        prevButton: ".mcase-prev",
        breakpoints: {
          1000: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      });
    },
    //合作案列
    getArticleList () {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 20, type: 12 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
          setTimeout(() => {
            this.swiperInit();
          }, 500);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
