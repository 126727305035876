<template>
  <div class="el_供需对接">
    <banner />
    <div class="mrow gxdj">
      <div class="wrapper">
        <introduce />
        <Enterprise />
      </div>
    </div>
    <Case />
  </div>
</template>

<script>
import banner from "./banner.vue";
import introduce from "./introduce.vue";
import Enterprise from "./enterprise.vue";
import Case from "./case.vue";

export default {
  data() {
    return {
      msg: "el_供需对接",
    };
  },
  components: {
    banner,
    introduce,
    Enterprise,
    Case,
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
