<template>
  <div>
    <div class="msearch">
      <img class="icon" src="../../../assets/images/icon-search.png" />
      <input
        type="text"
        class="text"
        name=""
        value=""
        placeholder="搜索输入"
        v-model="message"
      />
      <button @click="getArticleList()">搜 索</button>
    </div>
    <div class="mlatest">
      <div class="mtit1">
        <h2 class="cn">最新需求</h2>
        <div class="en">Latest demand</div>
        <div class="line"></div>
      </div>
      <div class="mlist-box">
        <ul class="mlist">
          <li v-for="item in list" :key="item.id"  @click="toRouter('article', item)">
            <div class="img">
              <a>
                <img :src="item.cover"
              /></a>
            </div>
            <h3>{{ item.title }}</h3>
            <h4>{{ item.subtitle }}</h4>
            <div class="txt">
              {{ item.articleAbstract }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data() {
    return {
      message: "",
      list: [],
    };
  },
  mounted() {
    this.getArticleList();
  },
  methods: {
    toRouter(path, data) {
      newRouter("/" + path, {
        id: data.id,
      });
    },
    getArticleList() {
      this.api
        .getTabArticleList({
          pageNo: 1,
          pageSize: 50,
          type: 8,
          keyword: this.message,
        })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
